import { AjaxSync } from '../shared/ajax_utilities'

let $createTachoUserProfile
$(function() {
  setupTachoProfileCreate();
})

function setupTachoProfileCreate()
{
  $createTachoUserProfile = app.DOM.form.find('button#create_digicard');
  $createTachoUserProfile.off('click').on('click', fireAjaxToCreateTachoProfile)
}

function fireAjaxToCreateTachoProfile (e) {
  e.preventDefault()

  const data = {
    user_id: app.URI[2],
    first_name: app.DOM.form.find('#first_name').val(),
    last_name: app.DOM.form.find('#last_name').val(),
    card_country: app.DOM.form.find('#digicard_region').val(),
    card_number: app.DOM.form.find('#digicard_number').val(),
  }

  AjaxSync(
    {
      method: 'POST',
      url: app.CACHE.URL_AJAX + 'create_tacho_profile',
      data
    },
    {
      done: [ function (res) {
        if (res && res.errors && res.errors.length > 0) {
            app.TOAST.error("Failed to create tachograph profile");
        } else {
          window.location.reload()
        }
      }]
    })
}